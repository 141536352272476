import { makeRoute } from './makeRoute';

export const home = makeRoute(() => `/`, 'marketing');
export const about = makeRoute(() => `/about`, 'marketing');
export const learn = makeRoute(() => `/learn`, 'marketing');
export const donors = makeRoute(() => `/donors`, 'marketing');
export const orgs = makeRoute(() => `/orgs`, 'marketing');
export const advisors = makeRoute(() => `/advisors`, 'marketing');
export const otc = makeRoute(() => `/otc`, 'marketing');
export const donationCalculator = makeRoute(() => `/calculators/donation`, 'marketing');
export const ndao = makeRoute(() => `/ndao`, 'marketing');
export const impact = makeRoute((args: { year?: number }) => {
  if (!args.year) return `/impact`;
  return `/impact/${args.year}`;
}, 'marketing');
export const _404 = makeRoute(() => `/404`, 'marketing');
